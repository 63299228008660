import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'user-student-main-view',
  computed: {
    ...mapGetters({
      basicCountries: 'setting/basicCountries'
    })
  },
  created () {
    this.changeSkip(0)
    if (this.$route.name !== 'user.students.create') {
      this.fetchCurrentUser({ id: this.$route.params.id })
    }
    if (!this.basicCountries.length) {
      this.fetchBasicCountries()
    }
  },
  methods: {
    ...mapActions({
      fetchBasicCountries: 'setting/GET_BASIC_COUNTRIES',
      fetchCurrentUser: 'userStudents/GET_LIST_ITEM'
    }),
    ...mapMutations({
      changeSkip: 'courses/COURSES_LIST_CHANGE_SKIP'
    })
  }
}
